
import { Vue } from 'vue-class-component'
import apiService from '@/services/apiService'
import { UserData } from '@/types'
import { animatedClose, animatedOpen } from '@/animations/index'

export default class SearchToolbar extends Vue {
  results: UserData[] = []
  query = ''
  isLoading = false
  noResults = false
  delayCount = 0
  delayTimer: number | undefined

  async input(event: any) {
    this.isLoading = true
    clearTimeout(this.delayTimer)
    this.delayCount++
    let currentDelay = this.delayCount
    this.query = event.target.value.trim()
    if (event.target.value.trim() !== '') {
      this.delayTimer = setTimeout(async () => {
        if (this.query.trim() !== '')
          await apiService
            .findUserByEmail(event.target.value)
            .then(async (response) => {
              if (currentDelay === this.delayCount) {
                if (response.data.length === 0) this.noResults = true
                else this.noResults = false
                this.isLoading = false
                this.results = response.data
                await animatedOpen('.results')
              }
            })
      }, 500)
    } else {
      await animatedClose('.results')
      this.isLoading = false
      this.noResults = false
      this.results = []
    }
  }

  async goToDetail(user: UserData) {
    await animatedClose('.results')
    this.results = []

    this.query = ''
    this.$router.push({ name: 'Detail', params: { id: user.email } })
  }

  async onClickAway() {
    await animatedClose('.results')
    this.results = []
  }

  reset() {
    this.delayCount++
    this.query = ''
  }
}
