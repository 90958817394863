<template>
  <transition
    name="custom"
    v-on:after-enter="afterEnter"
    v-on:after-leave="leave"
    appear
  >
    <div class="login-page">
      <div id="transition-left"></div>
      <div v-if="error" class="notification">
        <div v-if="error === 401">
          The password seems to be wrong. Please check your password and try
          again.
        </div>
        <div v-else>
          An unknown error occurred. Please try again or contact the application
          administrator.
        </div>
      </div>
      <div class="is-flex is-flex-direction-row row">
        <div class="left is-flex is-flex-direction-column is-hidden-touch">
          <h2 class="slogan mb-5">
            TO CREATE A HEALTHIER AND MORE SUSTAINABLE WORLD BY SUPPORTING HONEY
            BEES.
          </h2>
          <div class="varoa">
            <img rel="preload" src="../assets/varoa.svg" alt="varoa" />
          </div>
        </div>
        <div class="container login-container">
          <h3 class="login">Sign in to Vatorex Support</h3>
          <input
            class="password input is-hovered"
            type="password"
            v-model="password"
            placeholder="Type password here ..."
            autocomplete="on"
          />
          <br />
          <button class="button" @click="login">Sign in</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import axios from '@/services/http'
import { gsap } from 'gsap'

export default class Login extends Vue {
  error: null | string | number = null

  password = ''

  login() {
    let token = window.btoa('vatorex:' + this.password)
    axios
      .get('/hello', {
        headers: {
          Authorization: `Basic ${token}`,
        },
      })
      .then(() => {
        this.error = null
        this.$store.commit('login', token)
      })
      .catch((error) => {
        if (error?.response?.status) this.error = error.response.status
        else this.error = 'unknown error'
      })
  }

  afterEnter(el) {
    gsap
      .timeline({})
      .to('#transition-left', {
        width: window.innerWidth < 1024 ? 0 : '516px',
        duration: 0.75,
        ease: 'power2.inOut',
      })
      .to(
        '#transition-left',
        {
          autoAlpha: 0,
          duration: 0.5,
        },
        '-0.2s'
      )
  }
}
</script>
<style lang="scss">
.login-page {
  z-index: 11;
  position: relative;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
<style scoped lang="scss">
@import '../styles/_variables.scss';
.notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  color: $white;
  background-color: $dark-red;
  border-radius: 0;
}
.row {
  min-height: 100vh;
  .left {
    width: 516px;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 100vh;
    padding: 70px 70px 40px;
    background-color: $yellow;
    .slogan {
      margin-top: 86px;
      text-align: left;
      @media screen and (max-height: 750px) {
        font-size: 36px;
      }
      .varoa {
        margin-top: 80px;
        img {
          width: 593px;
          height: 404px;
        }
      }
    }
  }
  .login {
    width: 306px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 400px) {
      font-size: 18px;
    }
  }

  .password {
    @media screen and (max-width: 400px) {
      width: calc(100vw - 2rem);
      margin: 0 1rem;
      margin-top: 22px;
      padding: 13px 16px 13px 16px;
    }
    width: 346px;
    height: 50px;
    padding: 13px 150px 13px 16px;
    border-radius: 8px;
    border: $border-input;
    background-color: $light-grey;
    margin: 0 auto;
    margin-top: 22px;
  }
  .button {
    @media screen and (max-width: 400px) {
      width: calc(100vw - 2rem);
      margin: 0 1rem;
      margin-top: 22px;
      padding: 14px 16px 13px 16px;
    }
    width: 346px;
    height: 50px;
    margin-top: 22px;
    margin-left: auto;
    margin-right: auto;
    padding: 14px 143px 13px 142px;
    border-radius: 8px;
    background-color: $yellow;
    font-family: 'Baloo 2';
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: -0.13px;
    text-align: center;
    color: $black;
    border: none;
    &:hover {
      filter: brightness(93%);
    }
  }
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
