<template>
  <div class="searchToolbar">
    <div class="search-wrapper" v-click-away="onClickAway">
      <div :class="['control', 'is-medium', isLoading ? 'is-loading' : '']">
        <input
          type="text"
          placeholder=" Search for email..."
          :class="[results.length > 0 ? 'open' : '']"
          :value="query"
          @input="input"
        />
      </div>
      <div
        v-if="query.trim() !== '' && !isLoading"
        @click="reset"
        class="clear-icon"
      >
        <img class="icon mr-3 mt-1" src="../assets/ic_cancel.svg" />
      </div>

      <div class="results">
        <div
          v-for="(item, index) in results"
          class="result"
          :key="'result_' + index"
          @click="() => goToDetail(item)"
        >
          {{ item.email }}
        </div>
        <div v-if="noResults" class="result">Keine Suchresultate gefunden</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import apiService from '@/services/apiService'
import { UserData } from '@/types'
import { animatedClose, animatedOpen } from '@/animations/index'

export default class SearchToolbar extends Vue {
  results: UserData[] = []
  query = ''
  isLoading = false
  noResults = false
  delayCount = 0
  delayTimer: number | undefined

  async input(event: any) {
    this.isLoading = true
    clearTimeout(this.delayTimer)
    this.delayCount++
    let currentDelay = this.delayCount
    this.query = event.target.value.trim()
    if (event.target.value.trim() !== '') {
      this.delayTimer = setTimeout(async () => {
        if (this.query.trim() !== '')
          await apiService
            .findUserByEmail(event.target.value)
            .then(async (response) => {
              if (currentDelay === this.delayCount) {
                if (response.data.length === 0) this.noResults = true
                else this.noResults = false
                this.isLoading = false
                this.results = response.data
                await animatedOpen('.results')
              }
            })
      }, 500)
    } else {
      await animatedClose('.results')
      this.isLoading = false
      this.noResults = false
      this.results = []
    }
  }

  async goToDetail(user: UserData) {
    await animatedClose('.results')
    this.results = []

    this.query = ''
    this.$router.push({ name: 'Detail', params: { id: user.email } })
  }

  async onClickAway() {
    await animatedClose('.results')
    this.results = []
  }

  reset() {
    this.delayCount++
    this.query = ''
  }
}
</script>

<style scoped lang="scss">
.searchToolbar {
  z-index: 200;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 400px;
  @media only screen and (min-width: 1024px) {
    max-width: 531px;
  }
  @media only screen and (max-width: 820px) {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 4rem;
    max-width: 100%;
  }
  .search-wrapper {
    width: 100%;
    border-radius: 8px;
    border: solid 1px #d6d6d6;
    background-color: #f3f3f3;
    input {
      width: 100%;
      height: 50px;
      padding-left: 52px;
      font-family: 'Nunito Sans';
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f2f2f;
      border: none;
      background-image: url('../assets/ic_search.svg');
      background-position: 16px 13px;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-color: transparent;
      &.open {
        border-bottom: solid 1px #d6d6d6;
      }
    }

    .results {
      background-color: transparent;
      max-height: 200px;
      width: 100%;
      z-index: 2;
      height: 0;
      opacity: 0;
      visibility: hidden;
      overflow: auto;
      .result {
        text-align: left;
        padding: 0.5rem;
        padding-left: 53px;
        &:hover {
          background-color: #d6d6d6;
        }
      }
    }
  }
  .clear-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50px;
    align-items: center;
  }
}
</style>
