<template>
  <transition name="fade" appear>
    <div class="search">
      <div class="container">
        <h1 class="title">Time to fix some problems...</h1>
        <SearchToolbar />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SearchToolbar from '@/components/SearchToolbar.vue'

@Options({
  name: 'Search',
  components: {
    SearchToolbar,
  },
})
export default class Search extends Vue {}
</script>

<style scoped lang="scss">
@import '../styles/_variables.scss';

.search {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: $yellow;
  .title {
    height: 50px;
    margin-top: 30vh;
    margin-bottom: 48px;
  }
  .subtitle {
    padding-top: 16px;
    margin-bottom: 100px;
  }
}
</style>
