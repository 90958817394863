import { gsap } from 'gsap'

export async function animatedOpen(selector) {
  await gsap.timeline({}).to(selector, {
    height: 'auto',
    duration: 0.5,
    opacity: 1,
    autoAlpha: 1,
  })
}
export async function animatedClose(selector) {
  await gsap.to(selector, {
    height: 0,
    duration: 0.5,
    opacity: 0,
    autoAlpha: 0,
  })
}

export function staggeredAnimatedOpen(selector) {
  gsap.to(selector, {
    duration: 0.3,
    opacity: 1,
    autoAlpha: 1,
    delay: 0.2,
  })
  gsap.to(selector, {
    height: 'auto',
    duration: 0.5,
  })
}
export function staggeredAnimatedClose(selector) {
  gsap.to(selector, {
    duration: 0.3,
    opacity: 0,
    autoAlpha: 0,
  })
  gsap.to(selector, {
    height: 0,
    duration: 0.5,
  })
}
