import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import Search from '../views/Search.vue'

import store from '../store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Search',
    component: Search,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/detail/:id',
    name: 'Detail',

    // route level code-splitting
    // this generates a separate chunk (detail.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "detail" */ '../views/Detail.vue'),
  },
  {
    path: '/mock/:entry?',
    name: 'Mock',
    component: () =>
      import(/* webpackChunkName: "detail" */ '../views/Detail.vue'),
  },
  {
    path: '/dev',
    name: 'Dev',
    component: () => import(/* webpackChunkName: "dev" */ '../views/Dev.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to: any, from: any, next: any) => {
  if (
    to.name !== 'Login' &&
    !store.state.isAuthenticated
    // && to.name !== 'Mock' /* uncomment to make mock routes public */
  ) {
    store.commit('setForwardUrl', to.fullPath)
    next({ name: 'Login' })
  } else next()
})

export default router
