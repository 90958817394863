<template>
  <div>
    <router-link to="/"
      ><div
        :class="[
          'vatorex',
          'is-flex',
          'is-flex-direction-row',
          $route.name !== 'Login' ? 'toolbar' : '',
        ]"
      >
        <img class="logo" src="./assets/vatorexLogo.svg" />
        <h3 class="companyName">vatorex</h3>
      </div></router-link
    >
    <div id="nav" v-if="isLoggedIn() && $route.name !== 'Login'">
      <span class="logout-link" @click="logout"
        >logout
        <!--<img class="logout-icon" src="./assets/logout-variant.svg" />-->
      </span>
    </div>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
    <div class="version ml-1">v{{ VUE_APP_VERSION }}</div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
import store from './store'

export default class App extends Vue {
  VUE_APP_VERSION = process.env.VUE_APP_VERSION

  logout() {
    store.commit('logout')
  }

  isLoggedIn() {
    return store.state.isAuthenticated
  }
}
</script>
<style lang="scss">
@import './styles/global.scss';
#app {
  position: relative;
}
</style>
<style lang="scss" scoped>
@import './styles/_variables.scss';

#transition-left {
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  background-color: $yellow;
  width: 100vw;
  height: 100vh;
}

#app {
  font-family: 'Nunito Sans', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 30px 1rem;
  position: absolute;
  right: 0;
  z-index: 1;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.logo {
  width: 72px;
  height: 72px;
}
.version {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  z-index: 10;
}
.logout-link {
  font-family: 'Baloo 2';
  font-weight: 700;
  color: black;
  padding: 8px 16px;
  border-radius: 21px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }
  .logout-icon {
    vertical-align: bottom;
  }
}

.vatorex {
  align-items: center;
  position: absolute;
  &.toolbar {
    align-items: center;
    margin-top: 0;
    margin-left: 1rem;
    height: 80px;
  }
  margin-top: 70px;
  margin-left: 70px;
  z-index: 100;
  .companyName {
    margin-left: 16px;
    margin-top: 3px;
  }
}
</style>
