
import { Options, Vue } from 'vue-class-component'
import SearchToolbar from '@/components/SearchToolbar.vue'

@Options({
  name: 'Search',
  components: {
    SearchToolbar,
  },
})
export default class Search extends Vue {}
