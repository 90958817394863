import http from './http'

class ApiService {
  getHello() {
    return http.get('/hello')
  }
  findUserByEmail(email: string) {
    return http.get('/users/' + email)
  }
  getDevicesByUsername(userName: string) {
    return http.get('/devices/' + userName)
  }
  getDatasetsByDatabase(dbName: string) {
    return http.get('/datasets/' + dbName)
  }
  getHardwareInfoByDatabase(dbName: string) {
    return http.get('/hardware/' + dbName)
  }
  getSensorDataBySerial(serial: number) {
    return http.get('/sensors/' + serial)
  }
  triggerReplication(testAccount: number, sourceDbName: string) {
    return http.get('/replication/' + testAccount + '/' + sourceDbName)
  }
}

export default new ApiService()
