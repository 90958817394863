
import { Vue } from 'vue-class-component'
import store from './store'

export default class App extends Vue {
  VUE_APP_VERSION = process.env.VUE_APP_VERSION

  logout() {
    store.commit('logout')
  }

  isLoggedIn() {
    return store.state.isAuthenticated
  }
}
