
import { Vue } from 'vue-class-component'
import axios from '@/services/http'
import { gsap } from 'gsap'

export default class Login extends Vue {
  error: null | string | number = null

  password = ''

  login() {
    let token = window.btoa('vatorex:' + this.password)
    axios
      .get('/hello', {
        headers: {
          Authorization: `Basic ${token}`,
        },
      })
      .then(() => {
        this.error = null
        this.$store.commit('login', token)
      })
      .catch((error) => {
        if (error?.response?.status) this.error = error.response.status
        else this.error = 'unknown error'
      })
  }

  afterEnter(el) {
    gsap
      .timeline({})
      .to('#transition-left', {
        width: window.innerWidth < 1024 ? 0 : '516px',
        duration: 0.75,
        ease: 'power2.inOut',
      })
      .to(
        '#transition-left',
        {
          autoAlpha: 0,
          duration: 0.5,
        },
        '-0.2s'
      )
  }
}
