import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from '@/services/http'
import VueClickAway from 'vue3-click-away'
import Rollbar from 'rollbar'

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Basic ' + token
  store.commit('login', token)
}

const app = createApp(App)

const rootComponent = app.use(store).use(router).use(VueClickAway).mount('#app')

if (process.env.NODE_ENV === 'production') {
  app.provide(
    '$rollbar',
    new Rollbar({
      accessToken: 'ddaac002b5d44da98a74d18778b0ad93',
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: 'production',
      },
    })
  )
}

// global error handler
app.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV === 'production') {
    // @ts-ignore
    vm.$rollbar.error(err)
  }
  throw err // rethrow
}
