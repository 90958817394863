import { createStore } from 'vuex'
import router from '../router'
import axios from '@/services/http'

const store = createStore({
  state: {
    isAuthenticated: false,
    forwardUrl: null,
    collapseState: {
      initialized: false,
      apibrain: null,
      hiveConnect: null,
      hiveConnectCombs: null,
      comb: null,
    },
  },
  getters: {
    collapseState: (state, type, id) => {
      return state.collapseState.initialized
        ? state.collapseState[type].get(id)
        : null
    },
    hasExpanded: (state) => {
      return (
        Object.values(state.collapseState.apibrain!).some(
          (item) => item === true
        ) ||
        Object.values(state.collapseState.hiveConnect!).some(
          (item) => item === true
        ) ||
        Object.values(state.collapseState.hiveConnectCombs!).some(
          (item) => item === true
        )
      )
    },
  },
  mutations: {
    login(state, token) {
      state.isAuthenticated = true
      //@ts-ignore
      router.push(state.forwardUrl ? state.forwardUrl : '/')
      localStorage.setItem('token', token)
      axios.defaults.headers.common['Authorization'] = 'Basic ' + token
      store.commit('resetForwardUrl')
    },
    logout(state) {
      localStorage.removeItem('token')
      delete axios.defaults.headers.common['Authorization']
      state.isAuthenticated = false
      router.push('/login')
    },
    setForwardUrl(state, url) {
      state.forwardUrl = url
    },
    resetForwardUrl(state) {
      state.forwardUrl = null
    },
    setCollapseState(state, collapseState) {
      state.collapseState = collapseState
    },
    setCollapseStateValue(state, params) {
      state.collapseState[params.type][params.item] = params.value
    },
    collapseAll(state) {
      Object.keys(state.collapseState.apibrain!).forEach(
        //@ts-ignore
        (v) => (state.collapseState.apibrain[v] = false)
      )
      Object.keys(state.collapseState.hiveConnect!).forEach(
        //@ts-ignore
        (v) => (state.collapseState.hiveConnect[v] = false)
      )
      Object.keys(state.collapseState.hiveConnectCombs!).forEach(
        //@ts-ignore
        (v) => (state.collapseState.hiveConnectCombs[v] = false)
      )
      Object.keys(state.collapseState.comb!).forEach(
        //@ts-ignore
        (v) => (state.collapseState.comb[v] = false)
      )
    },
  },
  actions: {},
  modules: {},
})
export default store
